export const useOsDetection = () => {
  const getOsName = () => {
    // appVersion is deprecated, here it's being used as a fallback value
    const appVersion = navigator.appVersion;
    const userAgent = navigator.userAgent;

    let os = 'Windows';

    const osTypes = [
      {
        name: 'Windows',
        reg: /Windows\s(?:NT\s(?:10\.0|6\.[0-3]|5\.2|5\.[01]|4\.0)|Win(?:dows)?(?:\s(?:ME|98|95))?|Win(?:NT|16))/
      },
      {
        name: 'Mobile',
        reg: /(?:Mobile|mini|Fennec|Android|iP)/
      },
      {
        name: 'Linux',
        reg: /(?:Linux|X11)/
      },
      {
        name: 'MacOS',
        reg: /Mac(?:PPC|Intel|_PowerPC|intosh| OS X)/
      }
    ];

    for (const osType of osTypes) {
      if (osType.reg.test(userAgent) || osType.reg.test(appVersion)) {
        os = osType.name;
        break;
      }
    }

    return os;
  };

  return {
    getOsName
  };
};