export class ExternalDataHelper {
  getQueryParamsObjFunction?: () => Promise<object>;

  constructor(options: { getQueryParamsObjFunction?: () => Promise<object> }) {
    if (options.getQueryParamsObjFunction) {
      this.getQueryParamsObjFunction = options.getQueryParamsObjFunction;
    }
  }


  public makeBeaconRequest(url: string, data: any): boolean {
    const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
    return navigator.sendBeacon(url, blob);
  }

  public async getQueryParams(): Promise<string> {
    if (this.getQueryParamsObjFunction) {
      const queryParamsObj: any = await this.getQueryParamsObjFunction();
      if (queryParamsObj && Object.keys(queryParamsObj).length > 0) {
        return new URLSearchParams(queryParamsObj).toString();
      }
    }
    return '';
  }

  public validateData(data: {
    feature: any;
    data: { id: any; content: any }[];
  }): void {
    if (!data.feature) {
      throw new Error('feature branch required');
    }

    if (!Array.isArray(data.data)) {
      throw new Error('payload should be array');
    }

    if (!data.data.length) {
      throw new Error('payload is empty array');
    }

    for (const dataObject of data.data) {
      if (!dataObject.id) {
        throw new Error('payload data is missing id');
      }
      if (!dataObject.content) {
        throw new Error('payload data is missing content');
      }
    }
  }

}