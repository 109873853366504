export default defineNuxtPlugin({
  parallel: true,
  setup() {
    // Imported from 'utils/page-view-event.ts'
    if (process.env.NODE_ENV === 'production') {
      captureURLChangeEvent('onboarding');
    }
  },
  hooks: {
    'app:mounted'() {
      if (process.env.NODE_ENV === 'production') {
        capturePageLoadEvent('onboarding');
      }
    }
  }
});
