export function useLTISession() {

  const setLtiProcessingLogin = (newVal: any) => {
    useState('ltiProcessingLogin').value = newVal;
  };

  const getLtiProcessingLogin = () => {
    return useState('ltiProcessingLogin').value;
  };

  const setLtiPassPortSessionCreated = (newVal: any) => {
    useState('ltiPassPortSessionCreated').value = newVal;
  };

  const getLtiPassPortSessionCreated = () => {
    return useState('ltiPassPortSessionCreated').value;
  };

  return {
    setLtiProcessingLogin,
    getLtiProcessingLogin,
    setLtiPassPortSessionCreated,
    getLtiPassPortSessionCreated
  };
}
