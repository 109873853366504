import validate from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.16.2_@unocss+reset@0.62.4_axios@1.7.5_encodi_yf3wjrg4qnsyq3iaehecst4tye/node_modules/nuxt/dist/pages/runtime/validate.js";
export const globalMiddleware = [
  validate
]
export const namedMiddleware = {
  "check-authenticated": () => import("/app/layers/base/middleware/check-authenticated.ts"),
  "common-loader": () => import("/app/apps/onboarding/middleware/common-loader.ts"),
  "lti-onboarding": () => import("/app/apps/onboarding/middleware/lti-onboarding.ts"),
  "page-redirection": () => import("/app/apps/onboarding/middleware/page-redirection.ts"),
  "parent-login": () => import("/app/apps/onboarding/middleware/parent-login.ts"),
  "redirect-home": () => import("/app/apps/onboarding/middleware/redirect-home.ts")
}