export default defineNuxtPlugin((nuxtApp) => {
    const { setIsGigyaLoaded, checkGigyaAndDlsSession, getUserDataFromSession } = useAuthService();
    const { bindGigyaLoadEvent, subscribeToGigyaLoaded } = useGigyaSession();
    const publicConfig = useRuntimeConfig().public;
    const onboardingFooterLinkToBeHandled = [
        '/privacy',
        '/terms',
        '/institution-request',
        '/accessibility',
        '/error',
        '/lti-error',
        '/generic-lti-error',
    ];

    nuxtApp.hook('app:mounted', () => {
        bindGigyaLoadEvent();
        /**
         * This is to handle the case where the user's backend session exipres and the page is refreshed.
         * In this case, we need to check if the user's Gigya session is valid.
         * If the Gigya session is valid, we need to fetch the user data from the session and login the user.
         * This is done only for the footer link of onboarding app and all routes of other apps.
         * */


        subscribeToGigyaLoaded(async () => {
            setIsGigyaLoaded(true);
            if (
                publicConfig.APP_NAME !== 'onboarding' ||
                (publicConfig.APP_NAME === 'onboarding' && onboardingFooterLinkToBeHandled.includes(window.location.pathname))
            ) {
                await getUserDataFromSession({
                    fetchFromApi: publicConfig.APP_NAME === 'onboarding' && onboardingFooterLinkToBeHandled.includes(window.location.pathname)
                });
                await checkGigyaAndDlsSession();
            }
        });
    });
});