/**
 * ExternalData initialization Class. Provides public functions
 */
import { config } from './config/config';
import { ExternalDataHelper } from './external-data-helper';

interface ExternalDataOptions {
  appVersion?: string;
  serverRoutePrefix?: string;
  getQueryParamsObjFunction?: () => Promise<object>;
}

class ExternalData {
  externalDataHelper: ExternalDataHelper;
  appVersion: string;
  serverRoutePrefix: string;
  getQueryParamsObjFunction?: () => Promise<object>;

  constructor(options: ExternalDataOptions) {
    this.externalDataHelper = new ExternalDataHelper(options);
    this.appVersion = options.appVersion || config.appVersion;

    this.serverRoutePrefix = options.serverRoutePrefix || config.serverRoutePrefix;

    if (options.getQueryParamsObjFunction) {
      this.getQueryParamsObjFunction = options.getQueryParamsObjFunction;
    }

  }

  public async sendBeacon(data: any): Promise<boolean> {
    try {
      this.validateData(data);

      let url = `${this.serverRoutePrefix}/beacon/external-data`;
      url += await this.getQueryParams();

      return this.makeBeaconRequest(url, data);
    } catch (err) {
      throw new Error(err);
    }
  }

  private validateData(data: any): void {
    this.externalDataHelper.validateData(data);
  }

  private makeBeaconRequest(url: string, data: any): boolean {
    return this.externalDataHelper.makeBeaconRequest(url, data);
  }

  private async getQueryParams(): Promise<string> {
    return await this.externalDataHelper.getQueryParams();
  }
}

export { ExternalData };