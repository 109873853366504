<template>
  <div class="main h-100" style="font-family: Mulish, Sans-serif">
    <NuxtLayout />
  </div>
  <CommonLoader />
</template>

<script setup>
const { activateTheme } = useThemeService();
const nuxtApp = useNuxtApp();
const router = useRouter();
const {setLocale } = useI18n();
const { lang } = useLocalStorage();
onBeforeMount(async() => {
  activateTheme();
  let locale= window.localStorage.getItem(lang);
  if(locale){
    locale= locale.charAt(0).toUpperCase() + locale.slice(1);
    await setLocale(locale);
}
});

nuxtApp.hook('page:finish', () => {
  const savedPosition = router.options.history.state.scroll;
  if (savedPosition) {
    window.scrollTo({
      top: savedPosition.top,
      left: savedPosition.left,
      behavior: 'instant'
    });
  } else {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }
});
</script>
