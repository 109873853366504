export function useInvitationService() {
  const { isUserAuthenticated } = useAuthService();
  const {
    navigateUserBasedOnRole,
    navigateToDashboardApp,
    navigateToSupportAdminApp,
    navigateToAdminApp
  } = useInterAppNavigationService();
  const { invitationExpired } = useLocalStorage();
  const isInvitationExpiredModal: any = useState('invite-expire', () => false);
  const invitePreFill: any = useState('invite-prefill', () => false);
  const inviteParams: any = useState('invite-params', () => {
    return {
      orgId: '',
      context: '',
      classId: '',
      invitationId: '',
      invitationUrl: ''
    };
  });
  const inviteDetails: any = useState('invite-details', () => false);
  const userSpaceCount: any = useState('invite-userSpaceCount', () => null);
  const enabledProviders: any = useState('invite-enabledProviders', () => null);

  /**
   * Initializes the invitation service with the provided values.
   * @param orgIdValue - The organization ID.
   * @param contextValue - The context value.
   * @param classIdValue - The class ID.
   * @param invitationIdValue - The invitation ID.
   */
  const initService = (
    orgIdValue: string,
    contextValue: string,
    classIdValue: string,
    invitationIdValue: string
  ) => {
    const invitationUrl =
      orgIdValue && contextValue && classIdValue && invitationIdValue
        ? `${orgIdValue}/${contextValue}/${classIdValue}/invitation/${invitationIdValue}`
        : '';
    setInviteParams({
      orgId: orgIdValue,
      context: contextValue,
      classId: classIdValue,
      invitationId: invitationIdValue,
      invitationUrl: invitationUrl
    });
  };

  /**
   * Navigates to the teacher dashboard invitation URL.
   * If the user space count is not greater than or equal to 0, it fetches the user ID and sets it in the inviteDetails object.
   * If the user ID is present, it navigates to the join class route.
   * If the user ID is undefined, it navigates to the join space route.
   */
  const navigateTeacherDashboardInvitationUrl = async () => {
    const inviteParams = getInviteParams();
    const inviteDetails = getInviteDetails();
    const userSpaceCount = getUserSpaceCount();
    if (!userSpaceCount) {
      inviteDetails.userId = await $fetch(
        '/apigateway/userId/' + inviteParams.orgId
      ).then((res: any) => {
        return res.userId;
      });
      setInviteDetails(inviteDetails);
    }
    // if teacher is in same org--> userid present, then navigate to join class route
    if (inviteDetails.userId) {
      const url =
        inviteParams.orgId +
        '/' +
        inviteParams.context +
        '/' +
        inviteParams.classId +
        '/' +
        inviteDetails.userId +
        '/' +
        inviteParams.invitationId;
      navigateToDashboardApp(['invitation/teacher', url]);
    }
    // if teacher is not in same org--> userid is undefined, then navigate to join space route
    else {
      const url =
        inviteParams.orgId +
        '/' +
        inviteParams.context +
        '/' +
        inviteParams.classId +
        '/' +
        inviteParams.invitationId;
      navigateToDashboardApp(['invitation/teacher', url]);
    }
  };

  /**
   * Navigates to the learner dashboard invitation URL.
   * If the user has no space count till yet, it fetches the space count before navigating.
   * If the user is present in space, it navigates to the join class route.
   * If the user is not present in space, it navigates to the provision route.
   */
  const navigateLearnerDashboardInvitationUrl = async () => {
    const inviteParams = getInviteParams();
    const inviteDetails = getInviteDetails();
    let userSpaceCount = getUserSpaceCount();
    if (!userSpaceCount) {
      userSpaceCount = await $fetch('/apigateway/getSpaceCount').then(
        (res: any) => {
          return res.count;
        }
      );
      setUserSpaceCount(userSpaceCount);
    }
    // if learner has some space-->then navigate to join class route
    let url =
      inviteParams.orgId +
      '/' +
      inviteParams.context +
      '/' +
      inviteParams.classId +
      '/' +
      inviteParams.invitationId;
    if (!(inviteDetails.userId || userSpaceCount > 0)) {
      url += '?new=true';
    }
    navigateToDashboardApp(['invitation/learner', url]);
  };

  /**
   * Navigates the user to the appropriate dashboard based on their role.
   * @param userData - The user data containing the role information.
   */
  const navigateUserToInvitationDashboard = (userData: any) => {
    if (userData.role === 'student') {
      navigateToDashboardApp(['invitation', 'main']);
    } else if (userData.role === 'superadmin') {
      navigateToSupportAdminApp(['dashboard']);
    } else if (userData.role === 'teacher') {
      navigateToDashboardApp(['invitation', 'main']);
    } else if (userData.role === 'admin') {
      navigateToAdminApp(['invitation', 'main']);
    }
  };

  /**
   * Retrieves the details of an invitation.
   * @returns {Promise<any>} A promise that resolves to the invitation details.
   */
  const getInvitationDetails = async () => {
    const inviteParams = getInviteParams();
    return await $fetch('/apigateway/' + inviteParams.invitationUrl);
  };

  const getGigyaUserInfo = async (email: string) =>
    await $fetch('/apigateway/gigya/getUserInfo?email=' + email);

  /**
   * Processes the invitation for a user.
   *
   * @param orgIdValue - The organization ID.
   * @param contextValue - The context value.
   * @param classIdValue - The class ID.
   * @param invitationIdValue - The invitation ID.
   */
  const processInvite = async (
    orgIdValue: string,
    contextValue: string,
    classIdValue: string,
    invitationIdValue: string
  ) => {
    initService(orgIdValue, contextValue, classIdValue, invitationIdValue);
    let inviteDetailsResponse: any;
    try {
      //get invite details
      inviteDetailsResponse = await getInvitationDetails();

      //get user data
      const authResponse: any = await isUserAuthenticated();
      const userData = authResponse.user;

      // handle logged in users
      if (userData) {
        handleLoggedInUser(inviteDetailsResponse, userData);
      } else {
        // for user not logged in currently
        handleUsersNotLoggedIn(inviteDetailsResponse);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoggedInUser = (inviteDetailsResponse: any, userData: any) => {
    const isMismatchedEmail = inviteDetailsResponse.email !== userData.email;
    const isInvalidStatus = !(
      inviteDetailsResponse.status === 'pending' ||
      inviteDetailsResponse.status === 'revoke'
    );
    const isInvalidRole =
      userData.role === 'admin'
        ? inviteDetailsResponse.role !== 'teacher'
        : inviteDetailsResponse.role !== userData.role;

    if (
      !(
        inviteDetailsResponse.code === 'INVITATION_EXPIRED' &&
        inviteDetailsResponse.status === 'expire'
      )
    ) {
      if (isMismatchedEmail || isInvalidStatus || isInvalidRole) {
        navigateUserBasedOnRole(userData.role);
      } else {
        setInviteDetails(inviteDetailsResponse);
        if (inviteDetailsResponse.status === 'revoke') {
          localStorage.setItem(
            window.localStorage.getItem(invitationExpired) +
            userData.extUserId,
            'true'
          );
          navigateToDashboardApp(['invitation/invalidlink']);
        }
        //send user to dashboard for invitation
        else if (inviteDetailsResponse.role === 'teacher') {
          navigateTeacherDashboardInvitationUrl();
        } else {
          navigateLearnerDashboardInvitationUrl();
        }
      }
    } else {
      localStorage.setItem(
        window.localStorage.getItem(invitationExpired) +
        userData.extUserId,
        'true'
      );
      navigateToDashboardApp(['invitation/invalidlink']);
    }
  };

  /**
   * Handles the logic for users who are not logged in.
   *
   * @param inviteDetailsResponse - The response containing the invitation details.
   * @returns {Promise<void>}
   */
  const handleUsersNotLoggedIn = async (inviteDetailsResponse: any) => {
    // for user not logged in currently
    if (isInvitationExpiredOrRevoked(inviteDetailsResponse)) {
      initService('', '', '', '');
      showInvitationExpiredPopup();
    } else {
      setInviteDetails(inviteDetailsResponse);
      //check if user exists in gigya
      const gigyaUserInfo: any = await getGigyaUserInfo(
        inviteDetailsResponse.email
      );
      if (gigyaUserInfo?.exists) {
        handleExistingUser(gigyaUserInfo);
      } else {
        handleNewUser(inviteDetailsResponse);
      }
    }
  };

  const isInvitationExpiredOrRevoked = (inviteDetailsResponse: any) => {
    return (
      (inviteDetailsResponse.status === 'expire' &&
        inviteDetailsResponse.code === 'INVITATION_EXPIRED') ||
      inviteDetailsResponse.status === 'revoke'
    );
  };

  const handleExistingUser = (gigyaUserInfo: any) => {
    //if user is registered via site
    setInvitePreFill(true);
    if (gigyaUserInfo.info.socialProviders.indexOf('site') > -1) {
      navigateTo({ path: '/login' });
    } else {
      //if user is not registered via (facebook only) or (google only), then both facebook and google enabled by default
      let enabledProviders = 'facebook,googleplus';
      if (gigyaUserInfo.info.socialProviders === 'facebook') {
        // if user is registered via facebook only
        enabledProviders = 'facebook';
      } else if (gigyaUserInfo.info.socialProviders === 'googleplus') {
        // if user is registered via google only
        enabledProviders = 'googleplus';
      }
      setEnabledProviders(enabledProviders);
      navigateTo({ path: '/social-login' });
    }
  };

  const handleNewUser = (inviteDetailsResponse: any) => {
    //for users not registered in gigya redirect to the registration screen corresponding to the invitation role
    setInvitePreFill(true);
    if (inviteDetailsResponse.role === 'teacher') {
      navigateTo({ path: '/register-teacher' });
    } else {
      navigateTo({ path: '/register-learner' });
    }
  };

  /**
   * Shows the invitation expired popup and navigates to the home page.
   */
  const showInvitationExpiredPopup = async () => {
    setInvitationExpiredModal(true);
    navigateTo('/home');
  };

  /**
   * Handles the login process for a user.
   *
   * @param userData - The user data.
   */
  const handleLogIn = (userData: any) => {
    const inviteDetails = getInviteDetails();
    const inviteParams = getInviteParams();
    const userSpaceCount = getUserSpaceCount();
    //if Child account managed by parent
    if (userData.hasParent || !userData.email) {
      navigateUserBasedOnRole(userData.role);
      return;
    }
    //In case if the learner came from invitation email,after login navigate to dashboard invitation route
    if (
      inviteParams.invitationUrl &&
      userData.email == inviteDetails.email &&
      inviteDetails.status == 'pending'
    ) {
      handleInvitationUrl(userData, inviteDetails);
    } else {
      handleNormalLogin(userData, userSpaceCount);
    }
  };

  /**
   * Handles the invitation URL based on the user's role and invite details.
   * If the user is a student and the invite details specify a student role, it navigates to the learner dashboard invitation URL.
   * If the user is a student and the invite details specify a role other than student, it navigates based on the user's role.
   * If the user is a teacher or admin and the invite details specify a teacher role, it navigates to the teacher dashboard invitation URL.
   * If the user is a teacher or admin and the invite details specify a role other than teacher, it navigates based on the user's role.
   * @param userData The user data.
   * @param inviteDetails The invitation details.
   */
  const handleInvitationUrl = (userData: any, inviteDetails: any) => {
    if (userData.role == 'student') {
      if (inviteDetails.role == 'student') {
        navigateLearnerDashboardInvitationUrl();
      } else {
        navigateUserBasedOnRole(userData.role);
      }
    }
    if (userData.role == 'teacher' || userData.role == 'admin') {
      if (inviteDetails.role == 'teacher') {
        navigateTeacherDashboardInvitationUrl();
      } else {
        navigateUserBasedOnRole(userData.role);
      }
    }
  };

  /**
   * Handles the normal login flow based on the user data and space count.
   * If the user has spaces or a pending invitation, it navigates the user based on their role.
   * Otherwise, it checks for any pending invitations.
   *
   * @param userData - The user data.
   * @param userSpaceCount - The count of spaces the user has.
   */
  const handleNormalLogin = (userData: any, userSpaceCount: any) => {
    //check if it exists in no space, then check pending invitation
    if (userSpaceCount > 0 || getInviteDetails().userId) {
      navigateUserBasedOnRole(userData.role);
    } else {
      checkPendingInvitations(userData);
    }
  };

  /**
   * Checks for pending invitations and navigates the user accordingly.
   * @param userData - The user data.
   */
  const checkPendingInvitations = (userData: any) => {
    $fetch('/apigateway/checkPendingInvitations').then(
      (pendingResponse: any) => {
        // if have pending invitation, enroll in it
        if (pendingResponse?.count > 0) {
          navigateUserToInvitationDashboard(userData);
        } else {
          //if no pending invitation, navigate to dashboard
          navigateUserBasedOnRole(userData.role);
        }
      }
    );
  };

  const setUserSpaceCount = (count: any) => {
    userSpaceCount.value = count;
  };

  const getUserSpaceCount = () => {
    return userSpaceCount.value;
  };

  const getInviteParams = () => {
    return inviteParams.value;
  };

  const setInviteParams = (newVal: any) => {
    inviteParams.value = newVal;
  };

  const getInvitePreFill = () => {
    return invitePreFill.value;
  };

  const setInvitePreFill = (newVal: any) => {
    invitePreFill.value = newVal;
  };

  const getInvitationExpiredModal = () => {
    return isInvitationExpiredModal.value;
  };

  const setInvitationExpiredModal = (newVal: any) => {
    isInvitationExpiredModal.value = newVal;
  };

  const getInviteDetails = () => {
    return inviteDetails.value;
  };

  const setInviteDetails = (newVal: any) => {
    inviteDetails.value = newVal;
  };

  const getEnabledProviders = () => {
    return enabledProviders.value;
  };

  const setEnabledProviders = (newVal: any) => {
    enabledProviders.value = newVal;
  };

  return {
    initService,
    getInviteParams,
    setUserSpaceCount,
    processInvite,
    getInvitePreFill,
    setInviteDetails,
    getInviteDetails,
    getEnabledProviders,
    getInvitationExpiredModal,
    setInvitationExpiredModal,
    handleLogIn
  };
}
