import { useLocalStorage } from '~/../../layers/base/composables/localstorage';
let loginProvider: any;

export default defineNuxtPlugin(() => {
  //Adding storage events for tab refresh on login/logout
  //These are added outside onNuxtReady as they are needed for server side rendered pages also like terms
  window.addEventListener('storage', (ev) => {
    const { userLoggedIn, userLoggedOut } = useLocalStorage();
    if (ev.storageArea == localStorage && ev.key == userLoggedIn) {
      setTimeout(() => {
        if (localStorage.getItem(userLoggedIn))
          localStorage.removeItem(userLoggedIn);
        window.location.reload();
      }, 1000);
    }
    if (ev.storageArea == localStorage && ev.key == userLoggedOut) {
      setTimeout(() => {
        if (localStorage.getItem(userLoggedOut))
          localStorage.removeItem(userLoggedOut);
        sessionStorage.removeItem(
          useRuntimeConfig().public.sessionStorage['userAccount']
        );
        window.location.reload();
      }, 1000);
    }
  });

  onNuxtReady(async () => {
    publishOnboardingGigyaMeta();
    loginProvider = useState('gigya-loginProvider', () => false);

    //attach login event handler with gigya
    if (location.pathname.startsWith('/child-registration')) {
      return;
    }

    if (window['gigya']) {
      handleGigyaOnLogin();
    } else {
      window.addEventListener('gigyaLoaded', () => {
        handleGigyaOnLogin();
      });
    }

    window.addEventListener('gigyaLoaded', () => {
      //fetch gigya screen-sets if current url is home
      // added Index path("/") since Home page is also loaded on index path
      if (
        location.pathname === '/' ||
        location.pathname === '/home' ||
        location.pathname === '/login' ||
        location.pathname === '/2023/home' ||
        location.pathname === '/2023/login'
      ) {
        window['gigya'].accounts.showScreenSet({
          screenSet: 'CAMBRIDGEONE-RegistrationLogin',
          startScreen: 'nemo-login',
          containerID: 'hidden_gigya_div'
        });
      }
    });

    window.addEventListener('GIGYA_AFTER_SUBMIT', (data: any) => {
      if (data.detail.event.screen == 'term-update-screen') {
        if (data.detail.event.response.loginProvider == 'saml-edulog') {
          loginProvider.value = data.detail.event.response.loginProvider;
        }
      }
    });
  });
});

function handleGigyaOnLogin() {
  window.gigya.accounts.addEventHandlers({
    onLogin: onLogin
  });
}

async function handleSAMLUsers(userData: any) {
  // there is a potential security issue here
  // user samlData and email id should be crosschecked with gigya servers in backend
  const samlData = await getUserSAMLData();
  if (!samlData) return {};

  if (
    samlData.eltCustomerServices == 'true' ||
    samlData.eltSuperAdmin == 'true' ||
    samlData.eltSales == 'true'
  ) {
    return samlData;
  }
  //samlData is present without necessary claims
  if (
    !(
      location.pathname.includes('/okta') ||
      userData.provider == 'saml-@cambridge.org'
    )
  ) {
    //NEMO-2420: allow users having samlData but not having necessary claims coming via normal login
    return {};
  }

  window.gigya.accounts.logout();
  return false;
}

// Function to add/update login count of a user id.
function setLoginCount(UID: string) {
  const { login } = useLocalStorage();
  // get local-storage key of login object carrosponding to user-id.
  const key = login(UID);
  const userLoginObj = JSON.parse(localStorage.getItem(key));
  let updatedUserLoginObj;
  if (userLoginObj) {
    updatedUserLoginObj = {
      count: userLoginObj.count + 1
    };
  } else {
    updatedUserLoginObj = {
      count: 1
    };
  }
  localStorage.setItem(key, JSON.stringify(updatedUserLoginObj));
}

async function onLogin(userData: any) {
  if (location.pathname.startsWith('/child-registration')) {
    return;
  }

  const { activateTheme, calculateAppTheme } = useThemeService();
  const { getJWT } = useGigyaSession();
  const {
    getInviteDetails,
    setInviteDetails,
    getInviteParams,
    setUserSpaceCount,
    handleLogIn
  } = useInvitationService();
  const { setParentConsentGranted } = useOnboardingAuth();
  const {
    getLtiProcessingLogin,
    getLtiPassPortSessionCreated,
    setLtiPassPortSessionCreated
  } = useLTISession();
  const { generateLoginEvent, logEvents } = useUserTracker();

  if (getLtiProcessingLogin() || getLtiPassPortSessionCreated()) {
    if (getLtiPassPortSessionCreated()) {
      setLtiPassPortSessionCreated(false);
    }
    return;
  }
  //set login provider for edulog in case user has registered from edulog
  if (!userData.provider) {
    userData.provider = loginProvider.value;
  }
  //if user email belongs to @cambridge.org
  //change implementation if need be
  if (
    userData.profile.email &&
    userData.profile.email.indexOf('@cambridge.org') != -1
  ) {
    const samlData = await handleSAMLUsers(userData);
    //missing samlData means user doesn't have the required permissions, and got redirected to the error page
    if (!samlData && typeof samlData === 'boolean') {
      return;
    }

    userData.samlData = samlData;
  }
  useState('processingLogin').value = true;
  const isUserPasswordResetted = useState('is-user-password-resetted');
  if (isUserPasswordResetted) {
    const passwordResetSuccessfulKey =
      useLocalStorage().passwordResetSuccessful + userData.ext_user_id;
    localStorage.setItem(passwordResetSuccessfulKey, 'true');
    useState('is-user-password-resetted').value = true;
  }
  const jwt = await getJWT();
  const inviteDetails = getInviteDetails();
  const inviteParams = getInviteParams();
  const body = {
    UID: userData.UID,
    profile: userData.profile,
    c1UserRole: getC1UserRole(userData),
    provider: userData.provider,
    samlData: userData.samlData,
    isSpaceCountNeeded: !inviteDetails.userId,
    orgId: inviteParams.orgId,
    jwtToVerify: jwt
  };
  const theme = calculateAppTheme(userData.c1UserRole);
  activateTheme(theme);

  $fetch('/apigateway/login', {
    method: 'POST',
    body: body
  }).then(async (res: any) => {
    if (res?.user?.role === 'student') {
      try {
        const loginEvent = generateLoginEvent(res);
        await logEvents([loginEvent]);
      } catch (error) {
        console.log(
          'Error encountered while logging events for tracking user concurrency ' +
          JSON.stringify(error)
        );
      }
    }
    useState('processingLogin').value = false;
    setUserSpaceCount(res.userSpaceCount);
    if (res.userId && inviteDetails && !inviteDetails.userId) {
      inviteDetails.userId = res.userId;
      setInviteDetails(inviteDetails);
    }
    const { userLoggedIn } = useLocalStorage();
    localStorage.setItem(userLoggedIn, Date.now().toString());

    let redirectUrl = '/dashboard';
    const route = useRoute();

    // add/update login count of user.
    if (
      body.UID &&
      (body.c1UserRole === 'student' || body.c1UserRole === 'teacher')
    ) {
      setLoginCount(body.UID);
    }
    // if the query param u is present, redirect to desktop login page
    if (route.query.u) {
      navigateTo({ path: '/desktop-login', query: { u: route.query.u } });
      return;
    }
    if (route.query.rurl) {
      redirectUrl = route.query.rurl as string;
      window.location.href = redirectUrl;
    }
    //ielts query param handling to redirect the user to ielts app
    if (location.href.indexOf('ielts=true') != -1) {
      redirectUrl = useNavigation().constructMicroAppUrl(
        [],
        useRuntimeConfig().public.microapps.urlContracts.ielts
      );
      window.location.href = redirectUrl;
      return;
    } else if (res.user.role == 'student') {
      if (userData.parentUID) {
        res.user.hasParent = true;
      }
    } else if (res.user.role == 'parent') {
      const gigyaDataWithPref = await useGigyaSession().getGigyaUserData();
      if (!gigyaDataWithPref.preferences?.parentalConsent?.isConsentGranted) {
        setParentConsentGranted(false);
        navigateTo({ path: '/parental-consent' });
        return;
      } else {
        setParentConsentGranted(true);
      }
    }

    handleLogIn(res.user);
  });
}

function getC1UserRole(userData) {
  if (userData?.data?.eduelt?.instituteRole?.length > 0) {
    const nemoInstitute = userData.data.eduelt.instituteRole.find(
      (institute) => institute.platform == 'nemo'
    );
    if (nemoInstitute) return nemoInstitute.role;
  } else if (userData?.data?.nemo?.role) {
    return userData.data.nemo.role;
  } else {
    return '';
  }
}

async function getUserSAMLData() {
  return new Promise((resolve) => {
    window.gigya.accounts.getAccountInfo({
      extraProfileFields: 'samlData',
      callback: (res) => {
        resolve(res.profile.samlData);
      }
    });
  });
}
