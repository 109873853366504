export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('imageThumbnailUpdate', {
    mounted(el, binding) {
      const onErrorHandler = () => {
        if (binding.value) {
          binding.value.image = '';
        }
      };

      el.__onError__ = onErrorHandler;
      el.addEventListener('error', onErrorHandler);
    }
    // The `update` and `unbind` methods can be implemented if needed
  });
});
