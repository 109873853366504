import { type PageViewEvent } from 'cavalier';

// Session key to store the last visited URL for the case of inter app navigation
const SESSION_KEY = 'comprodls.nemo.lastVisitedURL';

// Publish event endpoint
const PUBLISH_EVENT_ENDPOINT = '/apigateway/cav-analytics';

/**
 * Captures page view events and sends them to the backend.
 * @param appName - The name of the application.
 */
export const capturePageLoadEvent = async (appName: string) => {
  // First time when the app is loaded
  // Retrieve the last visited URL from session storage for inter app navigation
  const lastVisitedURL = sessionStorage.getItem(SESSION_KEY);
  const oldUrl = lastVisitedURL ? new URL(lastVisitedURL) : null;
  sessionStorage.removeItem(SESSION_KEY);

  if (window.location.href) {
    const newUrl = new URL(window.location.href);
    const webPageEvent: PageViewEvent = _constructPageViewEvent(
      appName,
      newUrl,
      oldUrl
    );

    await _postEventData(webPageEvent);
  }
};

export const captureURLChangeEvent = (appName: string) => {
  // Save the last visited URL in session storage when navigating to another app
  // The app being redirected to will retrieve this URL to construct the page view event
  window.addEventListener('beforeunload', () => {
    sessionStorage.setItem(SESSION_KEY, window.location.href);
  });

  const router = useRouter();

  // let baseUrl = useRuntimeConfig().public.appBaseUrl ?? '';
  // if (baseUrl.endsWith('/')) {
  //   baseUrl = baseUrl.slice(0, -1);
  // }

  // Router navigation complete event, retrieve the old and new URL
  router.afterEach((to, from) => {
    // ignore if paths are same
    if (to.fullPath == from.fullPath) {
      return;
    }
    const oldUrl = new URL(`${from.fullPath}`, window.location.origin);

    const newUrl = new URL(`${to.fullPath}`, window.location.origin);

    // Construct the page view event and post data to the backend
    const webPageEvent = _constructPageViewEvent(appName, newUrl, oldUrl);

    _postEventData(webPageEvent);
  });
};

const _postEventData = async (eventPayload: PageViewEvent) => {
  try {
    await $fetch<boolean>(PUBLISH_EVENT_ENDPOINT, {
      method: 'POST',
      body: eventPayload
    });
  } catch (err) {
    console.log('Error publishing event for cavalier page view event. ', err);
  }
};

const _constructPageViewEvent = (
  appName: string,
  newURL: URL,
  oldURL: URL | null
) => {
  const { userAgent } = window.navigator;
  const { title } = window.document;

  const webPageEvent: PageViewEvent = {
    url: newURL.href,
    host: newURL.host,
    path: newURL.pathname,
    origin: oldURL?.href ?? null,
    title,
    user_agent: userAgent,
    app: appName
  };

  return webPageEvent;
};
