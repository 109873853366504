import { ofetch } from 'ofetch';
const { getGigyaUserData, getUserSAMLData, gigyaLogout } = useGigyaSession();

export default defineNuxtPlugin((nuxtApp) => {
  globalThis.$fetch = ofetch.create({
    // Execute logic before the HTTP request is sent
    onRequest({ request, options }) {
      options.headers = options.headers || {};
      options.headers[
        useRuntimeConfig().public.customReqHeaders.appVersion.key
      ] = useRuntimeConfig().public.customReqHeaders.appVersion.value;

      addRoleHeaderToRequestObject(options);

      // when no method is specified, then the default method is GET
      if (
        (!options.method || options.method === 'GET') &&
        isTimestampNeeded(request.toString())
      ) {
        options.query = options.query || {};
        options.query.timestamp = new Date().getTime();
      }

      // Standard CORS requests do not send or set any cookies by default.
      // In order to set/send cookie we need to set the .credentials property to include.
      options.credentials = 'include';
    },

    // Handle error related to server response
    onResponseError({ request, options, response }) {
      if (response._data.statusCode === 401 && response._data.data.errorCode === 'AUTHORIZATION_ERROR') {
        return unAuthorizedUserReLogin(globalThis, request, options);
      } else if (
        response._data.statusCode === 503 ||
        (response._data.statusCode === 403 &&
          response._data.errorCode === 'STALE_REQUEST')
      ) {
        reloadNuxtApp();
      } else if (
        response._data.statusCode === 401 &&
        response._data.errorCode === 'USER_MISMATCH_ERROR'
      ) {
        sessionStorage.removeItem(
          useRuntimeConfig().public.sessionStorage['userAccount']
        );
        reloadNuxtApp();
      }
    }
  });
});

// Caching of all files except html is avoided in order to make sure changes are reflected everytime.
const isTimestampNeeded = (url: string): boolean => {
  return (
    url.length > 5 && url.substring(url.length - 5, url.length) !== '.html'
  );
};

const addRoleHeaderToRequestObject = (options) => {
  let userData: any = sessionStorage.getItem(
    useRuntimeConfig().public.sessionStorage['userAccount']
  );
  userData = userData && JSON.parse(userData);

  if (userData?.role)
    options.headers[useRuntimeConfig().public.customReqHeaders.userRole.key] =
      userData.role;
  if (userData?.extUserId)
    options.headers[useRuntimeConfig().public.customReqHeaders.userId.key] =
      userData.extUserId;
};

const getUserDataFromGigya = async () => {
  const gigyaData = await getGigyaUserData();
  if (gigyaData) {
    const userData: any = {
      extUserId: gigyaData.UID,
      role: gigyaData.c1UserRole,
      email: gigyaData.profile.email,
      firstName: gigyaData.profile.firstName,
      lastName: gigyaData.profile.lastName
    }
    /* Safe Check - https://3.basecamp.com/4489886/buckets/20218208/messages/5124919146 */
    if (gigyaData.profile.email && gigyaData.profile.email.indexOf('@cambridge.org') != -1) {
      const samlData: any = await getUserSAMLData();
      if (samlData) {
        if (samlData.eltCustomerServices == "true" || samlData.eltSuperAdmin == "true" || samlData.eltSales == "true") {
          userData.samlData = samlData;
        } else {
          window['gigya'].accounts.logout();
        }
      }
    }
    return userData;
  } else {
    gigyaLogout();
    return Promise.reject(new Error("Gigya session not found."));
  }
}

const unAuthorizedUserReLogin = async (globalThis, request, options) => {
  const userData = await getUserDataFromGigya();
  let data = btoa(encodeURIComponent(JSON.stringify(userData)));
  options.query = options.query || {};
  options.query.userData = encodeURIComponent(data);
  return globalThis.$fetch(request, options);
}