import { nanoid } from 'nanoid';
import { ExternalData } from 'external-data';

export interface UserData {
  user: {
    extUserId: string;
    role: string;
    email: string;
    firstName: string;
    lastName: string;
  };
  userSpaceCount: number;
  userId: string;
}

export interface EventData {
  data: {
    actorid: string;
    os: string;
    timestamp: number;
    verb: string;
    xapi: boolean;
    user_role: string;
    navigated: {
      from: {
        page: string;
        url: string;
      };
    };
    ua: string;
    vw: number;
    vh: number;
  };
  action: string;
  category: string;
  type: string;
  originatorid: string;
}

export interface SanitizedEventData {
  id: string;
  content: EventData;
  opts: {
    'folder-structure': string;
  };
}

export const useUserTracker = () => {
  const { getOsName } = useOsDetection();

  const CONSTANTS = {
    FEATURE: 'user-sessions',
    EVENT_ACTIONS: {
      START: 'START'
    },
    EVENT_CATEGORIES: {
      SESSION: 'CUSTOM_SESSION'
    },
    EVENT_CATEGORY_TYPES: {
      SESSION: 'session'
    },
    EVENT_TYPES: {
      LEARNING: 'learning',
      SYSTEM: 'system'
    },
    VERBS: {
      NAVIGATED: 'navigated'
    },
    NAVIGATED_PAGE_NAMES: {
      LOGIN: 'login'
    }
  };

  const externalDataInstance = new ExternalData({
    serverRoutePrefix: '/apigateway'
  });

  function generateLoginEvent(userData: UserData): EventData {
    return {
      data: {
        actorid: userData.user.extUserId,
        os: getOS(),
        timestamp: Date.now(),
        verb: CONSTANTS.VERBS.NAVIGATED,
        xapi: false,
        user_role: userData.user.role,
        navigated: {
          from: {
            page: CONSTANTS.NAVIGATED_PAGE_NAMES.LOGIN,
            url: window.location.href
          }
        },
        ua: navigator.userAgent,
        vw: getViewPortWidth(),
        vh: getViewPortHeight()
      },
      action: CONSTANTS.EVENT_ACTIONS.START,
      category: CONSTANTS.EVENT_CATEGORIES.SESSION,
      type: CONSTANTS.EVENT_TYPES.LEARNING,
      originatorid: userData.user.extUserId
    };
  }

  async function logEvents(eventsArray: EventData[]): Promise<void> {
    const sanitizedData = sanitizeEventsData(eventsArray);
    await externalDataInstance.sendBeacon(sanitizedData);
  }

  function sanitizeEventsData(eventsArray: EventData[]): {
    feature: string;
    data: SanitizedEventData[];
  } {
    const sanitizedData = {
      feature: CONSTANTS.FEATURE,
      data: [] as SanitizedEventData[]
    };

    for (const content of eventsArray) {
      if (!content.category) continue;
      const id = getUniqueId();
      sanitizedData.data.push({
        id,
        content,
        opts: {
          'folder-structure': getFolderPath(content)
        }
      });
    }

    return sanitizedData;
  }

  function getUniqueId(): string {
    return nanoid(16);
  }

  function getOS(): string {
    return getOsName();
  }

  function getViewPortWidth(): number {
    return Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
  }

  function getViewPortHeight(): number {
    return Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
  }

  function getDate(timestamp?: number): string {
    timestamp = timestamp || Date.now();
    const nowDate = new Date(timestamp);
    return (
      nowDate.getFullYear() +
      '-' +
      (nowDate.getMonth() + 1) +
      '-' +
      nowDate.getDate()
    );
  }

  function getFolderPath(content: EventData): string {
    return `${content.data.actorid}/${getDate(content.data.timestamp)}/${content.category}`;
  }

  return {
    generateLoginEvent,
    logEvents
  };
};